import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import ScrollToTopButton from '../../components/scrollToTopButton/ScrollToTopButton'
import { useLocation } from 'react-router-dom'

export default function PrivacyPage() {

    const location = useLocation()

    const [isFixed, setIsFixed] = useState(false);
    const firstBlockRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const firstBlock = firstBlockRef.current;
            if (firstBlock) {
                const { bottom } = firstBlock.getBoundingClientRect();
                if (bottom < 0) {
                    setIsFixed(true);
                } else {
                    setIsFixed(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Volerò - Privacy Policy'
    }, [location])

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            const elementPosition = section.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - 70;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div>
            <Header />
            <div className='main'>
                <div className='gray__background' ref={firstBlockRef}>
                    <div className='container'>
                        <div className='privacy__fisrt__block'>
                            <div className='title'>Privacy Policy</div>
                            <p>This Privacy Policy will help you better understand how we collect, use, and share your personal information.</p>
                        </div>
                    </div>
                </div>
                <div className='white__background'>
                    <div className='container'>
                        <div className='privacy__second__block'>
                            <div className='terms__content'>

                                <div className='subtitle' id='Introduction'>Introduction</div>
                                <p>Welcome to Volero platform operated by Volero Tourism Ltd., an Israeli company (C.N 517015764), whose address is at 281 HaYuvalim St, Be'erotayim, Israel ("we," "us," or "our").
                                    We are committed to protecting your privacy and personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform [insert website URL] (the "Platform") and our services.</p>
                                <br /><br />

                                <div className='subtitle' id='Applicability'>Applicability</div>
                                <p>This policy applies to information we collect:</p>
                                <ul>
                                    <li>Through the Platform;</li>
                                    <li>In email, text, and other electronic messages between you and the Platform;</li>
                                    <li>Through mobile and desktop applications you download from the Platform;</li>
                                    <li>When you interact with our advertising and applications on third-party websites and services.</li>
                                </ul>
                                <br /><br />

                                <div className='subtitle' id='Information We Collect'>Information We Collect</div>
                                <p>We collect several types of information from and about users of our Platform, including:</p>
                                <ol type='a'>
                                    <li>Personal Information:</li>
                                    <ul>
                                        <li>Name;</li>
                                        <li>Email address;</li>
                                        <li>Postal address;</li>
                                        <li>Phone number;</li>
                                        <li>Date of birth;</li>
                                        <li>Passport details (when necessary for booking);</li>
                                        <li>Payment information.</li>
                                    </ul>
                                    <br />
                                    <li>Non-Personal Information:</li>
                                    <ul>
                                        <li>Browser type;</li>
                                        <li>IP address;</li>
                                        <li>Device information;</li>
                                        <li>Usage data.</li>
                                    </ul>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='How We Collect Information'>How We Collect Information</div>
                                <ol type='a'>
                                    <li>Information you provide to us;</li>
                                    <li>Automatic data collection technologies;</li>
                                    <li>Third-party sources (e.g., our partner airlines, hotels, and other service providers).</li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='How We Use Your Information'>How We Use Your Information</div>
                                <p>We use your information to:</p>
                                <ul>
                                    <li>Provide and improve our services;</li>
                                    <li>Process your bookings and transactions;</li>
                                    <li>Communicate with you about your bookings and our services;</li>
                                    <li>Personalize your experience on our Platform;</li>
                                    <li>Conduct analytics and research;</li>
                                    <li>Comply with legal obligations.</li>
                                </ul>
                                <br /><br />

                                <div className='subtitle' id='Disclosure of Your Information'>Disclosure of Your Information</div>
                                <p>We may disclose your personal information to:</p>
                                <ul>
                                    <li>Our subsidiaries and affiliates;</li>
                                    <li>Third-party service providers (e.g., airlines, hotels, car rental companies);</li>
                                    <li>To comply with legal obligations;</li>
                                    <li>To protect our rights, property, or safety.</li>
                                </ul>
                                <br /><br />

                                <div className='subtitle' id='Data Retention'>Data Retention</div>
                                <p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We may retain such information through the use of commonly accepted hosting services in Israel and/or in the EU. </p>
                                <br /><br />

                                <div className='subtitle' id='Data Security'>Data Security</div>
                                <p>We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.</p>
                                <br /><br />

                                <div className='subtitle' id='Your Rights'>Your Rights</div>
                                <p>Under Israeli privacy laws, you have the right to:</p>
                                <ul>
                                    <li>Access your personal information;</li>
                                    <li>Correct inaccurate personal information;</li>
                                    <li>Delete your personal information (subject to certain exceptions);</li>
                                    <li>Object to the processing of your personal information;</li>
                                    <li>Receive a copy of your personal information.</li>
                                </ul>
                                <p>To exercise these rights, please contact us using the information provided in Section 13.</p>
                                <br /><br />

                                <div className='subtitle' id='Child Privacy'>Children's Privacy</div>
                                <p>Our Platform is not intended for children under 18 years of age. We do not knowingly collect personal information from children under 18 (however, if you use the Platform to purchase services for minors, you may need to provide personal information related to such minors).</p>
                                <br /><br />

                                <div className='subtitle' id='Third-Party Links'>Third-Party Links</div>
                                <p>The Platform may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites.</p>
                                <br /><br />

                                <div className='subtitle' id='Changes to Our Privacy Policy'>Changes to Our Privacy Policy</div>
                                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>
                                <br /><br />

                                <div className='subtitle' id='Contact Information'>Contact Information</div>
                                <p>To ask questions or comment about this Privacy Policy and our privacy practices, contact us at:info@volero.net</p>
                                <br /><br />

                                <div className='subtitle' id='Consent'>Consent</div>
                                <p>By using our Platform, you consent to our Privacy Policy and agree to its terms.</p>
                                <br /><br />

                                <div className='subtitle' id='Compliance with Israeli Law'>Compliance with Israeli Law</div>
                                <p>This Privacy Policy is designed to comply with the Israeli Privacy Protection Law, 5741-1981, and related regulations. We are committed to protecting your privacy rights as required by Israeli law.</p>
                                <br /><br />

                                <div className='subtitle' id='International Data Transfers'>International Data Transfers</div>
                                <p>As we operate in the tourism industry, your information may be transferred to countries outside of Israel. We exercise commercially reasonable efforts to ensure that such transfers comply with applicable data protection laws and that appropriate safeguards are in place.</p>
                                <br /><br />

                                <div className='subtitle' id='Complaints'>Complaints</div>
                                <p>If you have a complaint about how we handle your personal information, please contact us first. If you are not satisfied with our response, you have the right to lodge a complaint with the Israeli Privacy Protection Authority.</p>
                                <br /><br />

                                <div className='subtitle' id='Language'>Language</div>
                                <p>This policy is provided in English and Hebrew. In case of any discrepancy between the two versions, the Hebrew version shall prevail.</p>
                                <br /><br />

                            </div>
                            <div className={`terms__list ${isFixed ? 'fixed' : ''}`}>
                                <div className='subtitle'>Table of Contents</div>
                                <div className='terms__link' onClick={() => scrollToSection('Introduction')}>Introduction</div>
                                <div className='terms__link' onClick={() => scrollToSection('Applicability')}>Applicability</div>
                                <div className='terms__link' onClick={() => scrollToSection('Information We Collect')}>Information We Collect</div>
                                <div className='terms__link' onClick={() => scrollToSection('How We Collect Information')}>How We Collect Information</div>
                                <div className='terms__link' onClick={() => scrollToSection('How We Use Your Information')}>How We Use Your Information</div>
                                <div className='terms__link' onClick={() => scrollToSection('Disclosure of Your Information')}>Disclosure of Your Information</div>
                                <div className='terms__link' onClick={() => scrollToSection('Data Retention')}>Data Retention</div>
                                <div className='terms__link' onClick={() => scrollToSection('Data Security')}>Data Security</div>
                                <div className='terms__link' onClick={() => scrollToSection('Your Rights')}>Your Rights</div>
                                <div className='terms__link' onClick={() => scrollToSection('Child Privacy')}>Children's Privacy</div>
                                <div className='terms__link' onClick={() => scrollToSection('Third-Party Links')}>Third-Party Links</div>
                                <div className='terms__link' onClick={() => scrollToSection('Changes to Our Privacy Policy')}>Changes to Our Privacy Policy</div>
                                <div className='terms__link' onClick={() => scrollToSection('Contact Information')}>Contact Information</div>
                                <div className='terms__link' onClick={() => scrollToSection('Consent')}>Consent</div>
                                <div className='terms__link' onClick={() => scrollToSection('Compliance with Israeli Law')}>Compliance with Israeli Law</div>
                                <div className='terms__link' onClick={() => scrollToSection('International Data Transfers')}>International Data Transfers</div>
                                <div className='terms__link' onClick={() => scrollToSection('Complaints')}>Complaints</div>
                                <div className='terms__link' onClick={() => scrollToSection('Language')}>Language</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <ScrollToTopButton />
        </div>
    )
}
