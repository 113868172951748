import React, { useEffect, useState } from 'react';
import './index.css';
import { useLocation } from 'react-router-dom';
import { getActiveTeam, getTeam } from '../../api';
import { PUBLIC_URL } from '../../config';

export default function TeamView() {

    const location = useLocation()
    const [filteredTeam, setFilteredTeam] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchTeam()
    }, [location])

    const fetchTeam = async () => {
        try {
            const teamList = await getActiveTeam();
            const activeMembers = teamList.filter(member => member.status === "active");
            const sortedMembers = activeMembers.sort((a, b) => a.numberList - b.numberList);
            const topFourMembers = sortedMembers.slice(0, 4);
            console.log(topFourMembers);
            setFilteredTeam(topFourMembers)
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch team agents!');
            setLoading(false);
        }
    }

    return (
        <div className='head__team'>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {!loading && !error && (
                <>
                    {filteredTeam.map((item) => (
                        <a className="card" target='_blank' rel="noopener noreferrer" href={`${item.linkedUrl}`}>
                            <img src={`${PUBLIC_URL}/${item.image}`} alt="Card" className="card__image" draggable='false' />
                            <div className="card__overlay">
                                <img className="linkedin__icon" src='/linkinWhite.webp' alt='linkidin' />
                                <div>
                                    <div className="card__name">{item.name}</div>
                                    <div className="card__position">{item.position}</div>
                                </div>
                            </div>
                        </a>
                    ))}
                </>
            )}
            {/* <a className="card" target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/in/tzafrir-ben-avinoam-0659465?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'>
                <img src="/tzafir.webp" alt="Card" className="card__image" draggable='false' />
                <div className="card__overlay">
                        <img className="linkedin__icon" src='/linkinWhite.webp' alt='linkidin' />
                    <div>
                        <div className="card__name">Tzafrir Ben Avinoam</div>
                        <div className="card__position">Co Founder & CEO</div>
                    </div>
                </div>
            </a>
            <a className="card" target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/in/irina-lorynets?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'>
                <img src="/Irina.webp" alt="Card" className="card__image" draggable='false' />
                <div className="card__overlay">
                    <img className="linkedin__icon" src='/linkinWhite.webp' alt='linkidin' />
                    <div>
                        <div className="card__name">Irina Lorynets</div>
                        <div className="card__position">Marketing & BD manager</div>
                    </div>
                </div>
            </a>
            <a className='card' target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/in/sharona-avitay-0842b8197/'>
                <img src="/sharona.webp" alt="Card" className="card__image" draggable='false' />
                <div className="card__overlay">
                    <img className="linkedin__icon" src='/linkinWhite.webp' alt='linkidin' />
                    <div>
                        <div className="card__name">Sharona Avitay</div>
                        <div className="card__position">Sales Manager</div>
                    </div>
                </div>
            </a>
            <a className='card' target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/in/sharona-avitay-0842b8197/'>
                <img src="/noa.webp" alt="Card" className="card__image" draggable='false' />
                <div className="card__overlay">
                    <img className="linkedin__icon" src='/linkinWhite.webp' alt='linkidin' />
                    <div>
                        <div className="card__name">Noa Weiss</div>
                        <div className="card__position">Tailor Made Manager</div>
                    </div>
                </div>
            </a> */}
        </div>
    );
}
