import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import './index.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { getReservations, getUserFromLoyalty, postAuth, postTokenIrix, refreshDataInTablesAgentsAndBookings } from '../../api';
import { notification } from 'antd';

export default function LoginPage() {

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'preconnect';
        link.href = `${process.env.REACT_APP_API_URL}`;

        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, []);

    const location = useLocation()
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get('source');

    useEffect(() => {
        window.scrollTo(0, 0)
        if (location.pathname.includes('/login?source=main')) {
            document.title = 'Volerò - Login'
        } else if (location.pathname.includes('/login?source=loyalty')) {
            document.title = 'Volerò - Loyalty'
        }
    }, [location])

    const [salesId, setSalesId] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleLoginMain = async (e) => {
        e.preventDefault();
        window.location.href = 'https://www.volero.net'
    }

    const handleLoginLoyalty = async (e) => {
        e.preventDefault();

        try {
            const response = await getUserFromLoyalty(salesId, username);

            if (response !== undefined) {
                notification.success({
                    message: 'Successful login',
                    description: 'You have successfully logged into the loyalty system!',
                    duration: 3
                });
                navigate(`/points/${response.data.salesId}/${response.data.username}/${response.data.id}`, {
                    state: { agent: response.data }
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Agent not found or blocked',
                    duration: 3
                });
            }
        } catch (error) {
            console.error('Error during authorization or fetching reservations:', error.response?.data || error.message);
            notification.error({
                message: 'Error',
                description: 'The server is not responding',
                duration: 3
            });
        }
    }

    const handleLoginAdminPanel = async (e) => {
        e.preventDefault()

        try {

	    if ('caches' in window) {
                await caches.keys().then(cacheNames => {
                    return Promise.all(
                        cacheNames.map(cache => caches.delete(cache))
                    );
                });
            }

            let accessToken = localStorage.getItem('accessTokenIrixAdmin');
            if (!accessToken) {
                console.log('No token found, requesting a new one...');
                const tokenResponse = await postTokenIrix()

                console.log("Token irix"+tokenResponse);

                if (tokenResponse && tokenResponse.data && tokenResponse.data.access_token) {
                    const accessToken = tokenResponse.data.access_token;
                    localStorage.setItem('accessTokenIrixAdmin', accessToken)
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Access token not received',
                        duration: 3
                    });
                }
            } else {
                console.log('Using existing token from localStorage:', accessToken);
            }

            const reservationsResponse = await getReservations(accessToken)
	    console.log(reservationsResponse);
            if (reservationsResponse.status === 200) {
		console.log(reservationsResponse);
                await refreshDataInTablesAgentsAndBookings(reservationsResponse)
            } else {
                console.log('Error creating or updating data');
            }

            await postAuth(username, password, navigate)

        } catch (error) {
            console.error('Error during authorization or fetching reservations:', error.response?.data || error.message);
        }
    }




    // const handle2FACodeSubmit = async (code) => {
    //     try {
    //         const response = await axios.post('https://www.volero.net/reseller/auth/2fa/authenticator', {
    //             code: code,
    //         }, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             }
    //         });

    //         if (response.status === 200) {
    //             console.log('2FA successful');
    //         }
    //     } catch (error) {
    //         console.error('Ошибка двухфакторной аутентификации:', error);
    //     }
    // };



    return (
        <div>
            <Header />
            <div className='main'>
                <div className={window.innerWidth > 426 ? 'container' : ''}>
                    <div className='white__background'>
                        <div className='register__text'>
                            <div className='title'>Welcome to Volerò</div>
                            <p>Fill in your login credentials to access the platform</p>
                        </div>
                        {source === 'main' && (
                            <div className={window.innerWidth > 426 ? 'login__block' : 'login__block__mobile'}>
                                <button className='already__btn' onClick={() => { navigate('/register') }}>
                                    <u>I don't have an account</u>
                                </button>
                                <form className="login__form" onSubmit={handleLoginMain}>
                                    <div className="log__form__group">
                                        <label htmlFor="sales__id">Sales ID</label>
                                        <input
                                            type="text"
                                            id="sales__id"
                                            value={salesId}
                                            onChange={(e) => setSalesId(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className="log__form__group">
                                        <label htmlFor="username">Username</label>
                                        <input
                                            type="text"
                                            id="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className="log__form__group">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            id="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <button type="submit" className='log__btn'>Login</button>
                                </form>
                            </div>
                        )}
                        {source === 'loyalty' && (
                            <div className={window.innerWidth > 426 ? 'login__block' : 'login__block__mobile'}>
                                <form className="login__form" onSubmit={handleLoginLoyalty}>
                                    <div className="log__form__group">
                                        <label htmlFor="sales__id">Sales ID</label>
                                        <input
                                            type="text"
                                            id="sales__id"
                                            value={salesId}
                                            onChange={(e) => setSalesId(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className="log__form__group">
                                        <label htmlFor="username">Username</label>
                                        <input
                                            type="text"
                                            id="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <button type="submit" className='log__btn'>Login</button>
                                </form>
                            </div>
                        )}
                        {source === 'adminPanel' && (
                            <div className={window.innerWidth > 426 ? 'login__block' : 'login__block__mobile'}>
                                <form className="login__form" onSubmit={handleLoginAdminPanel}>
                                    <div className="log__form__group">
                                        <label htmlFor="sales__id">Username</label>
                                        <input
                                            type="text"
                                            id="sales__id"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className="log__form__group">
                                        <label htmlFor="username">Password</label>
                                        <input
                                            type="text"
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <button type="submit" className='log__btn'>Login</button>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
