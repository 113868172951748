import React, { useState } from 'react';
import './index.css';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import { searchAgents, searchArticles, searchCoefficients, searchLanguages, searchRequestForPoints, searchRequestRegister, searchResume, searchStopList, searchTags, searchTeam, searchTestimonials, searchVacancies } from '../../api';

export default function Filter({ setFilteredItems, refreshData, exportToCSV }) {

    const location = useLocation()

    const [isOpen, setIsOpen] = useState(false);
    const [titleTag, setTitleTag] = useState('');
    const [salesIdCoef, setSalesIdCoef] = useState('');
    const [percentage, setPercentage] = useState('');
    const [code, setCode] = useState('')
    const [titleLanguage, setTitleLanguage] = useState('')
    const [salesIdStopList, setSalesIdStopList] = useState('')
    const [usernameStopList, setUsernameStopList] = useState('')
    const [titleVacancy, setTitleVacancy] = useState('')
    const [statusVacancy, setStatusVacancy] = useState('')
    const [nameResume, setNameResume] = useState('')
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [titleArticle, setTitleArticle] = useState('')
    const [statusArticle, setStatusArticle] = useState('')
    const [author, setAuthor] = useState('')
    const [relation, setRelation] = useState('')
    const [countOfStars, setCountOfStars] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [usernameRequestForPoints, setUsernameRequestForPoints] = useState('')
    const [email, setEmail] = useState('')
    const [reseller, setReseller] = useState('')
    const [salesIdAgent, setSalesIdAgent] = useState('')
    const [usernameAgent, setUsernameAgent] = useState('')
    const [nameTeam, setNameTeam] = useState('')
    const [listNumberTeam, setListNumberTeam] = useState('')

    const toggleFilter = () => {
        setIsOpen(!isOpen);
    };

    const resetData = () => {
        setTitleTag('');
        setCode('');
        setTitleLanguage('');
        setSalesIdCoef('');
        setPercentage('');
        setSalesIdStopList('')
        setUsernameStopList('')
        setTitleVacancy('')
        setStatusVacancy('')
        setNameResume('')
        setTitleArticle('')
        setStatusArticle('')
        setAuthor('')
        setRelation('')
        setCountOfStars('')
        setFirstName('')
        setLastName('')
        setUsernameRequestForPoints('')
        setEmail('')
        setSalesIdAgent('')
        setReseller('')
        setUsernameAgent('')
        setNameTeam('')
        setListNumberTeam('')
        setStartDate(null)
        setEndDate(null)
        setFilteredItems([]);
        refreshData();
    };

    const searchData = async () => {
        if (location.pathname.includes('/admin/tags')) {
            await searchTags(titleTag, setFilteredItems);
        } if (location.pathname.includes('/admin/coefficients')) {
            await searchCoefficients(salesIdCoef, percentage, setFilteredItems);
        } if (location.pathname.includes('/admin/languages')) {
            await searchLanguages(code, titleLanguage, setFilteredItems);
        } if (location.pathname.includes('/admin/stopList')) {
            await searchStopList(salesIdStopList, usernameStopList, setFilteredItems);
        } if (location.pathname.includes('/admin/vacancies')) {
            await searchVacancies(titleVacancy, statusVacancy, setFilteredItems);
        } if (location.pathname.includes('/admin/resume')) {
            await searchResume(nameResume, startDate, endDate, setFilteredItems);
        } if (location.pathname.includes('/admin/articles')) {
            await searchArticles(titleArticle, statusArticle, setFilteredItems);
        } if (location.pathname.includes('/admin/testimonials')) {
            await searchTestimonials(author, relation, countOfStars, setFilteredItems);
        } if (location.pathname.includes('/admin/requestRegister')) {
            await searchRequestRegister(firstName, lastName, startDate, endDate, setFilteredItems);
        } if (location.pathname.includes('/admin/requestForPoints')) {
            await searchRequestForPoints(usernameRequestForPoints, email, startDate, endDate, setFilteredItems);
        } if (location.pathname.includes('/admin/agents')) {
            await searchAgents(reseller, salesIdAgent, usernameAgent, setFilteredItems);
        } if (location.pathname.includes('/admin/team')) {
            await searchTeam(nameTeam, listNumberTeam, setFilteredItems);
        }
    }

    const renderFilterForm = () => {
        if (location.pathname === '/admin/coefficients') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Sales ID</label>
                            <input
                                className='input__coefficient'
                                type="text"
                                value={salesIdCoef}
                                onChange={(e) => setSalesIdCoef(e.target.value)}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Percentage</label>
                            <input
                                className='input__coefficient'
                                type="number"
                                value={percentage}
                                onChange={(e) => setPercentage(e.target.value)}
                                required
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn" onClick={resetData}>Reset</button>
                            <button className="search__btn" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            );
        } else if (location.pathname === '/admin/tags') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Title</label>
                            <input
                                className='input__tag'
                                type="text"
                                value={titleTag}
                                onChange={(e) => setTitleTag(e.target.value)}
                                required
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn" onClick={resetData}>Reset</button>
                            <button className="search__btn" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            );
        } else if (location.pathname === '/admin/languages') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Code</label>
                            <input
                                className='input__coefficient'
                                type="text"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Title</label>
                            <input
                                className='input__coefficient'
                                type="text"
                                value={titleLanguage}
                                onChange={(e) => setTitleLanguage(e.target.value)}
                                required
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn" onClick={resetData}>Reset</button>
                            <button className="search__btn" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else if (location.pathname === '/admin/stopList') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Sales ID</label>
                            <input
                                className='input__coefficient'
                                type="text"
                                value={salesIdStopList}
                                onChange={(e) => setSalesIdStopList(e.target.value)}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Username</label>
                            <input
                                className='input__coefficient'
                                type="text"
                                value={usernameStopList}
                                onChange={(e) => setUsernameStopList(e.target.value)}
                                required
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn" onClick={resetData}>Reset</button>
                            <button className="search__btn" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else if (location.pathname === '/admin/vacancies') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Title</label>
                            <input
                                className='input__coefficient'
                                type="text"
                                value={titleVacancy}
                                onChange={(e) => setTitleVacancy(e.target.value)}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Status</label>
                            <select
                                className='select__coefficient'
                                type="text"
                                value={statusVacancy}
                                onChange={(e) => setStatusVacancy(e.target.value)}
                                required
                            >
                                <option value=''></option>
                                <option value='active'>active</option>
                                <option value='draft'>draft</option>
                            </select>
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn" onClick={resetData}>Reset</button>
                            <button className="search__btn" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else if (location.pathname === '/admin/resume') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Name</label>
                            <input
                                className='input__resume'
                                type="text"
                                value={nameResume}
                                onChange={(e) => { setNameResume(e.target.value) }}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Created from</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Created to</label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn reset__btn__resume" onClick={resetData}>Reset</button>
                            <button className="search__btn search__btn__resume" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else if (location.pathname === '/admin/articles') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Title</label>
                            <input
                                className='input__coefficient'
                                type="text"
                                value={titleArticle}
                                onChange={(e) => setTitleArticle(e.target.value)}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Status</label>
                            <select
                                className='select__coefficient'
                                type="text"
                                value={statusArticle}
                                onChange={(e) => setStatusArticle(e.target.value)}
                                required
                            >
                                <option value=''></option>
                                <option value='active'>active</option>
                                <option value='draft'>draft</option>
                            </select>
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn" onClick={resetData}>Reset</button>
                            <button className="search__btn" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else if (location.pathname === '/admin/testimonials') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Author</label>
                            <input
                                className='input__testimonials'
                                type="text"
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Relation</label>
                            <select
                                className='input__testimonials'
                                type="text"
                                value={relation}
                                onChange={(e) => setRelation(e.target.value)}
                                required
                            >
                                <option value=''></option>
                                <option value='homePage'>Home Page</option>
                                <option value='aboutPage'>About Page</option>
                                <option value='productPage'>Product Page</option>
                                <option value='loyaltyPage'>Loyalty Page</option>
                            </select>
                        </div>
                        <div className='filter__item'>
                            <label>Count of Stars</label>
                            <input
                                className='input__testimonials'
                                type="number"
                                value={countOfStars}
                                onChange={(e) => setCountOfStars(e.target.value)}
                                min='1'
                                max='5'
                                required
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn" onClick={resetData}>Reset</button>
                            <button className="search__btn" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else if (location.pathname === '/admin/requestRegister') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>First name</label>
                            <input
                                className='input__request__register'
                                type="text"
                                value={firstName}
                                onChange={(e) => { setFirstName(e.target.value) }}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Last name</label>
                            <input
                                className='input__request__register'
                                type="text"
                                value={lastName}
                                onChange={(e) => { setLastName(e.target.value) }}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Created from</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                className='input__request__register'
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Created to</label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                dateFormat="dd/MM/yyyy"
                                className='input__request__register'
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn reset__btn__resume" onClick={resetData}>Reset</button>
                            <button className="search__btn search__btn__resume" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else if (location.pathname === '/admin/requestForPoints') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Username</label>
                            <input
                                className='input__request__points'
                                type="text"
                                value={usernameRequestForPoints}
                                onChange={(e) => { setUsernameRequestForPoints(e.target.value) }}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Email</label>
                            <input
                                className='input__request__points'
                                type="text"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Created from</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                className='input__request__points'
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Created to</label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                dateFormat="dd/MM/yyyy"
                                className='input__request__points'
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn reset__btn__resume" onClick={resetData}>Reset</button>
                            <button className="search__btn search__btn__resume" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else if (location.pathname === '/admin/agents') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Reseller</label>
                            <input
                                className='input__resume'
                                type="text"
                                value={reseller}
                                onChange={(e) => { setReseller(e.target.value) }}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Sales ID</label>
                            <input
                                className='input__resume'
                                type="text"
                                value={salesIdAgent}
                                onChange={(e) => { setSalesIdAgent(e.target.value) }}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>Username</label>
                            <input
                                className='input__resume'
                                type="text"
                                value={usernameAgent}
                                onChange={(e) => { setUsernameAgent(e.target.value) }}
                                required
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn reset__btn__agent" onClick={resetData}>Reset</button>
                            <button className="export__btn export__btn__agent" onClick={exportToCSV}>Export</button>
                            <button className="search__btn search__btn__agent" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else if (location.pathname === '/admin/team') {
            return (
                <div className="filter__body">
                    <div className="filter__row">
                        <div className='filter__item'>
                            <label>Name</label>
                            <input
                                className='input__coefficient'
                                type="text"
                                value={nameTeam}
                                onChange={(e) => setNameTeam(e.target.value)}
                                required
                            />
                        </div>
                        <div className='filter__item'>
                            <label>List number</label>
                            <input
                                className='input__coefficient'
                                type="text"
                                value={listNumberTeam}
                                onChange={(e) => setListNumberTeam(e.target.value)}
                                required
                            />
                        </div>
                        <div className='btn__actions'>
                            <button className="reset__btn" onClick={resetData}>Reset</button>
                            <button className="search__btn" onClick={searchData}>Search</button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <p>No filter available for this page</p>;
        }
    };

    return (
        <div className="filter__container">
            <div className="filter__header" onClick={toggleFilter}>
                <div className='filter__title'>Filter</div>
                <span>{isOpen ? '▾' : '▸'}</span>
            </div>

            {isOpen && renderFilterForm()}
        </div>
    );
}
