import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteTeam, getPicture, getTeam } from '../../api';
import Filter from '../../components/filter/Filter';
import { notification, Pagination } from 'antd';
import './index.css'

export default function TeamPage() {

    const location = useLocation();
    const navigate = useNavigate();

    const [filteredTeam, setFilteredTeam] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        document.querySelector('.main__content__admin').scrollTo(0, 0);
        fetchTeam();
    }, [location]);

    const fetchTeam = async () => {
        try {
            await getTeam(setFilteredTeam);
            setLoading(false);
            setNoResults(false);
        } catch (error) {
            setError('Failed to fetch team!');
            setLoading(false);
        }
    };

    const deleteData = async (id) => {
        try {
            const response = await deleteTeam(id);
            if (response.status === 200 || response.status === 204) {
                notification.success({
                    message: 'Successful',
                    description: 'Team agent has been deleted',
                    duration: 3,
                });
                fetchTeam();
            } else {
                console.error('Failed to delete team agent:', response);
                notification.error({
                    message: 'Error',
                    description: 'Failed to delete team agent',
                    duration: 3,
                });
            }
        } catch (error) {
            console.error('Error:', error);
            notification.error({
                message: 'Error',
                description: 'The server is not responding',
                duration: 3,
            });
        }
    };

    const onPageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
        window.scrollTo(0, 0);
    };

    const paginatedTeam = filteredTeam.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    return (
        <div className="all__content__admin">
            <div className="title__admin">Team</div>
            <div className="add__btn__block">
                <button className="add__btn" onClick={() => navigate('/admin/team/add')}>
                    + Add
                </button>
            </div>
            <Filter
                setFilteredItems={(filtered) => {
                    setFilteredTeam(filtered);
                    setNoResults(filtered.length === 0);
                }}
                refreshData={fetchTeam}
            />
            <div className="content__block__admin">
                {loading && <p>Loading...</p>}
                {error && <p>{error}</p>}
                {noResults && !loading && <p>No team found.</p>}
                {!loading && !error && !noResults && (
                    <table className="team__list">
                        <thead>
                            <tr>
                                <th className="list__title__admin">Image</th>
                                <th className="list__title__admin">Name</th>
                                <th className="list__title__admin">Position</th>
                                <th className="list__title__admin">Status</th>
                                <th className="list__title__admin">List number</th>
                                <th className="list__title__admin">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedTeam.map((team) => (
                                <tr className="team__item" key={team.id}>
                                    <td>
                                        {getPicture(team.image)}
                                    </td>
                                    <td>{team.name}</td>
                                    <td>{team.position}</td>
                                    <td>{team.status}</td>
                                    <td>{team.numberList}</td>
                                    <td className="article__actions">
                                        <button
                                            className="edit__btn"
                                            onClick={() =>
                                                navigate(`/admin/team/edit/${team.id}`, {
                                                    state: { team },
                                                })
                                            }
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="delete__btn"
                                            onClick={() => deleteData(team.id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <Pagination
                showQuickJumper
                current={currentPage}
                pageSize={pageSize}
                total={filteredTeam.length}
                onChange={onPageChange}
                showSizeChanger
                align='center'
                // pageSizeOptions={['5', '10', '20', '50']}
            />
        </div>
    )
}