import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import ScrollToTopButton from '../../components/scrollToTopButton/ScrollToTopButton'
import { useLocation } from 'react-router-dom'

export default function CookiesPolicyPage() {

    const location = useLocation()

    const [isFixed, setIsFixed] = useState(false);
    const firstBlockRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const firstBlock = firstBlockRef.current;
            if (firstBlock) {
                const { bottom } = firstBlock.getBoundingClientRect();
                if (bottom < 0) {
                    setIsFixed(true);
                } else {
                    setIsFixed(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Volerò - Terms of Use'
    }, [location])

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            const elementPosition = section.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - 70;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div>
            <Header />
            <div className='main'>
                <div className='gray__background' ref={firstBlockRef}>
                    <div className='container'>
                        <div className='terms__fisrt__block'>
                            <div className='title'>Cookies Policy</div>
                            {/* <p>This Privacy Policy will help you better understand how we collect, use, and share your personal information.</p> */}
                        </div>
                    </div>
                </div>
                <div className='white__background'>
                    <div className='container'>
                        <div className='terms__second__block'>
                            <div className='terms__content'>

                                <div className='subtitle' id='Introduction'>Introduction</div>
                                <p>Welcome to the Volero platform operated by Volero Tourism Ltd., an Israeli company (C.N 517015764), whose address is at 281 HaYuvalim St, Be'erotayim, Israel ("we," "us," or "our"). This Cookies Policy explains how we use cookies and similar technologies on our website: www.volero.net (the "Platform"). By using our Platform, you consent to the use of cookies as described in this policy.</p>
                                <br /><br />

                                <div className='subtitle' id='What are Cookies ?'>What are Cookies ?</div>
                                <p>Cookies are small text files that are placed on your device when you visit a website. They are widely used to make websites work more efficiently and provide information to website owners</p>
                                <br /><br />

                                <div className='subtitle' id='How We Use Cookies ?'>How We Use Cookies ?</div>
                                <p>We use cookies for the following purposes:</p>
                                <ol type='a'>
                                    <li>Essential cookies: These are necessary for the Site to function properly and cannot be switched off in our systems.</li>
                                    <li>Performance cookies: These allow us to count visits and traffic sources so we can measure and improve the performance of our Site.</li>
                                    <li>Functionality cookies: These enable the Site to provide enhanced functionality and personalization.</li>
                                    <li>Targeting cookies: These may be set through our Site by our advertising partners to build a profile of your interests and show you relevant ads on other sites.</li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Types of Cookies We Use'>Types of Cookies We Use</div>
                                <ol type='a'>
                                    <li>First-party cookies: These are cookies that we set on your device.</li>
                                    <li>Third-party cookies: These are cookies set by our partners and service providers, including:</li>
                                    <ul>
                                        <li>Analytics providers (e.g., Google Analytics)</li>
                                        <li>Advertising networks</li>
                                        <li>Flight booking systems</li>
                                        <li>Hotel reservation platforms</li>
                                        <li>Other tourism service providers</li>
                                    </ul>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Managing Cookies'>Managing Cookies</div>
                                <ol type='a'>
                                    <li>You can control and manage cookies through your browser settings to refuse cookies or delete certain cookies.</li>
                                    <li>Opt-out links: We provide opt-out links for certain third-party services in section 7 of this policy.</li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Data Protection'>Data Protection</div>
                                <p>We comply with applicable data protection laws, including the Israeli Privacy Protection Law, 5741-1981. For more information on how we process personal data, please refer to our Privacy Policy https://www.volero.net/reseller/privacy.</p>
                                <br /><br />

                                <div className='subtitle' id='Third-Party Services and Opt-Out'>Third-Party Services and Opt-Out</div>
                                <p>We work with Google Analytics services that may set cookies on your device. </p>
                                <br /><br />

                                <div className='subtitle' id='Changes to This Policy'>Changes to This Policy</div>
                                <p>We may update this Cookies Policy from time to time. We will notify you of any significant changes by posting the new policy on our Site.</p>
                                <br /><br />

                                <div className='subtitle' id='Contact Us'>Contact Us</div>
                                <p>If you have any questions about this Cookies Policy, please contact us at: </p>
                                <a href='https://www.volero.net/reseller/text/contactUs'>https://www.volero.net/reseller/text/contactUs</a>
                                <br /><br />

                                <div className='subtitle' id='Consent'>Consent</div>
                                <p>By using our Site, you consent to the use of cookies as described in this policy. If you do not agree to our use of cookies, you should adjust your browser settings accordingly or refrain from using our Site.</p>
                                <br /><br />

                                <div className='subtitle' id='Language'>Language</div>
                                <p>This policy is provided in English and Hebrew. In case of any discrepancy between the two versions, the Hebrew version shall prevail.</p>
                                <br /><br />

                            </div>
                            <div className={`terms__list ${isFixed ? 'fixed' : ''}`}>
                                <div className='subtitle'>Table of Contents</div>
                                <div className='terms__link' onClick={() => scrollToSection('Introduction')}>Introduction</div>
                                <div className='terms__link' onClick={() => scrollToSection('What are Cookies ?')}>What are Cookies ?</div>
                                <div className='terms__link' onClick={() => scrollToSection('How We Use Cookies ?')}>How We Use Cookies ?</div>
                                <div className='terms__link' onClick={() => scrollToSection('Types of Cookies We Use')}>Types of Cookies We Use</div>
                                <div className='terms__link' onClick={() => scrollToSection('Managing Cookies')}>Managing Cookies</div>
                                <div className='terms__link' onClick={() => scrollToSection('Data Protection')}>Data Protection</div>
                                <div className='terms__link' onClick={() => scrollToSection('Third-Party Services and Opt-Out')}>Third-Party Services and Opt-Out</div>
                                <div className='terms__link' onClick={() => scrollToSection('Changes to This Policy')}>Changes to This Policy</div>
                                <div className='terms__link' onClick={() => scrollToSection('Contact Us')}>Contact Us</div>
                                <div className='terms__link' onClick={() => scrollToSection('Consent')}>Consent</div>
                                <div className='terms__link' onClick={() => scrollToSection('Language')}>Language</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <ScrollToTopButton />
        </div>
    )
}
