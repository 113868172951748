import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import ScrollToTopButton from '../../components/scrollToTopButton/ScrollToTopButton'
import { useLocation } from 'react-router-dom'

export default function TermsOfUsePage() {

    const location = useLocation()

    const [isFixed, setIsFixed] = useState(false);
    const firstBlockRef = useRef(null);
    const lastItem = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            const firstBlock = firstBlockRef.current;
            if (firstBlock) {
                const { bottom } = firstBlock.getBoundingClientRect();
                if (bottom < 0) {
                    setIsFixed(true);
                } else {
                    setIsFixed(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Volerò - Terms of Use'
    }, [location])

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            const elementPosition = section.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - 70;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div>
            <Header />
            <div className='main'>
                <div className='gray__background' ref={firstBlockRef}>
                    <div className='container'>
                        <div className='terms__fisrt__block'>
                            <div className='title'>Terms of Use</div>
                            <p>This Privacy Policy will help you better understand how we collect, use, and share your personal information.</p>
                        </div>
                    </div>
                </div>
                <div className='white__background'>
                    <div className='container'>
                        <div className='terms__second__block'>
                            <div className='terms__content'>

                                <div className='subtitle' id='Introduction'>Introduction</div>
                                <p>Welcome to Volero, an online platform operated by Volero Tourism Ltd., an Israeli company (C.N 517015764), whose address is at 281 HaYuvalim St, Be'erotayim, Israel ("Volero," "we," "us," or "our"). These Terms and Conditions ("Terms") govern your use of our website and services, collectively the "Platform." By accessing or using the Platform, you agree to be bound by these Terms in their entirety, without any reservations. If you disagree, please do not use the Platform. </p>
                                <p>The Terms and are supplementary to any additional agreement that you may have with us ("Specific Agreement"), and in any case of contradiction between the Terms and such Specific Agreement, the provisions of the Specific Agreement shall prevail. </p>
                                <br /><br />

                                <div className='subtitle' id='Services'>Services</div>
                                <p>Volero provides a platform allowing users ("you," "User") to search for travel-related services, including, without limitation, hotel stays, flights, cruises, bed and breakfast, etc. (collectively: "Services") and purchase such Services from third-party travel providers ("Providers"). Volero acts as an intermediary and is not a provider of travel services.</p>
                                <p>We do not and will not assume any responsibility for the Services offered and/or representations made by Providers and does not guarantee in any way (explicitly or implicitly) the accuracy and completeness of information provided by Providers and/or the quality of the Services promoted through the Platform.</p>
                                <br /><br />

                                <div className='subtitle' id='General information published on the Platform'>General information published on the Platform</div>
                                <p>We may provide general information regarding certain destinations and attractions, weather conditions, time zones, visa requirements, etc. While we are taking great efforts to provide accurate and timely information, we cannot guarantee that the information provided on the Platform shall be accurate and up to date at all times, given the vast scope of such information and the frequent changes and updates of such information. We therefore strongly recommend that you verify any information that you wish to rely on through other official and unofficial sources. </p>
                                <p>You are more than welcome to inform us whenever you are of the opinion that information published on the Platform is lacking, inaccurate or outdated. We will consider such comments seriously wand willingly and will make any required changes accordingly. </p>
                                <br /><br />

                                <div className='subtitle' id='User Account'>User Account</div>
                                <ol>
                                    <li><strong>Account Creation.</strong> You may need to create an account to access certain features. It is your responsibility to provide your full an accurate name, contact details, and payment measures (where applicable). You are also required to keep such information accurate and updated and all times.  </li>
                                    <li><strong>Account Security.</strong> You are responsible for your account's security, including your password. You shall be held liable for any activity conducted through your account (e.g., bookings, cancellations, changes, etc.) You should notify us immediately whenever you suspect any unauthorized access.</li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Bookings'>Bookings</div>
                                <ol>
                                    <li><strong>Provider Contracts.</strong> Bookings are contracts between you and the Provider. Volero is not a party to these contracts.</li>
                                    <li><strong>Provider Terms.</strong> Bookings are subject to the Provider's terms and conditions, including cancellation and refund policies.</li>
                                    <li><strong>Payment Processing. </strong>Volero may process payments on behalf of Providers. You agree to pay all applicable charges, taxes, and fees.</li>
                                    <li>Any Services purchased through the Platform may only be used by the person(s) named on the respective booking form or for whom it has been purchased and may not be transferred to or used by anyone else. By submitting a booking form through the Platform, the person who submits such form (the "Applicant") declares that he or she is authorized to do so by all the other persons in the group for which the booking is made and confirms that the people named on the respective booking form accept the applicable booking conditions, and that the Applicant is responsible for the full cost of the Services so booked, including any cancellation or amendment charges. The Applicant should inform other members of the group of confirmation details and any other material information related to the booking.</li>
                                    <li>Bookings may be made only per bona fide. Volero may, at its reasonable discretion and upon providing the Applicant written notice (e-mail sufficient) cancel Bookings which do not appear to it genuine.</li>
                                    <li><strong>Group Reservations. </strong>Reservation requests for 10 and more people must be sent to Volero by email to Tailromade@volero.net.  Any attempt to artificially split groups of ten persons or more so to avoid such restrictions may result in the Providers levying charges for group cancellations or otherwise, for which the User will be liable. Providers may also unilaterally cancel bookings made through the Platform, if they appear to it to be group bookings masked as ordinary bookings.</li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Booking Confirmation'>Booking Confirmation</div>
                                <ol>
                                    <li>Communication re the confirmation of bookings shall be made via the Platform. Once a booking is completed, a voucher or electronic ticket with the reference number will be available online. The voucher must be saved and presented (electronically or in hard copy) as proof of booking.</li>
                                    <li>Volero reserves the right, upon providing the User a written notice (e-mail sufficient), to cancel or amend any booking made, in case of an error or mistake made by Volero or the Providers regarding rates, availability, etc. In such case Volero will not be obligated to pay to the Users any compensation other than to refund any amount paid for the reservation.</li>
                                    <li>You agree that passenger nationality declaration is mandatory at the time of booking and must be truthful and in accordance with the passenger passport. Failure to provide such information or provision of untruthful information may lead to undeceivable results and complications, for which we will not be responsible. Furthermore, the persons submitting such lacking or untruthful information through the Platform shall be solely liable (including, without limitation, to other travelers, to us or otherwise) for any financial and/or other damage that may occur due to such lacking or untruthful information.</li>
                                    <li>We will not be held responsible if the given passengers’ names are as UNKNOWN or TBA or FAKE, and they might not be considered as confirmed neither by the Providers nor by us. Also – it is the travelers' responsibility to check in advance and follow the Providers' instructions with regard to check-in times, permitted and prohibited luggage and luggage items, arrival to passport control, etc.</li>
                                    <li>Each booking must have full guest / PAX name per room, and the passenger whose details are provided first will be automatically assigned as lead name and the lead name will appear on all correspondence (example: vouchers, invoices, etc.). The lead name cannot be modified once the booking has been confirmed, without the Provider's consent. The providers reserve the right to reject the booking, without giving any notice, if the guest(s) full name(s) for each room booked is not provided.</li>
                                    <li>We recommend that you check the correctness of the information included in your order and confirmation forms (first name, last name, date of departure, destination). If you notice any errors, you must notify our customer service no later than 24 hours of booking and provide us with the correct data. Please note that in some cases the correction of such information may be subject to certain additional costs, since the rules for modification and cancellation of the reservation are defined by the Providers (especially low-cost airlines) and do not allow corrections resulting from the fault of the Applicant.</li>
                                    <li>In case that we become aware of any change in the time or terms of a Service booked by you through the Platform, for reasons related to the Provider (e.g., airlines) or to other external causes such as in case of flight delay / cancellation, we will try to contact you by phone or email, so to inform you of such changes. However, it is clarified and acknowledged that We are not and shall not be responsible for changes in travel, delays, canceled flights, or other changes caused by the Provider or by any other external circumstances. </li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Prices'>Prices</div>
                                <ol>
                                    <li>The prices offered to you over the Platform are confidential and may not be disclosed to any third party (other to the persons for whom you purchase Services), including any of Customer's subsidiaries, affiliates or parent company or any subsidiary of Customer's parent company.</li>
                                    <li>By using the Platform, you hereby commit to not use the prices quoted over the Platform to contact Our Providers and disclose such prices for the purpose of negotiating new rates and/or any other terms. If you breach this undertaking, you shall indemnify US for any damages caused to Us due to such breach.</li>
                                    <li>Prices quoted on the Platform are net prices (non-commissionable) including all indirect taxes, unless specified otherwise. Any increase in tax will be directly added to the prices offered over the Platform. Any other taxes such as tourist tax, resort fees and/or local, city, state taxes are not included. These taxes (“guest taxes”) need to be paid at the hotel by the passengers. Also, we are not responsible for guest taxes and have no control over their implementation.</li>
                                    <li>The prices quoted on the Platform do not include any products and services that are not explicitly specified in the reservation confirmation.</li>
                                    <li>Both price and availability of the selected service may be subject to change before confirmation of the booking. Once a booking is made, it shall no longer be affected by price changes (whether upwards or downwards) – but changes in taxation and guest taxes may apply. </li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Cancellations, Changes and Refunds'>Cancellations, Changes and Refunds</div>
                                <ol>
                                    <li>Provider Policies. Cancellations, changes and refunds are governed by the Provider's policies. Low-Cost Carriers (LCC) Bookings are subjected to 100% cancellation fees. Also – no reimbursement shall be allowed for bookings made based on non-refundable rates. Volero is not responsible for cancellations, changes or refunds.</li>
                                    <li>Contacting Providers. Contact the Provider directly for cancellations. We advise that you inform us as well, so that we may assist with communication. Yet, any cancellations, changes and/or refunds shall be subject to Provider's sole discretion, and we shall not have any liability for the Provider's decisions in this regard.</li>
                                    <li>In case that a Provider has accepted your direct request for cancellation or change, it is your responsibility to inform our Customer support Department in writing at Support@volero.net for all travel services changes. Unless you amend or cancel any booking through the Platform, you shall remain fully liable to us for any booking that was made by you, regardless of your direct dealings with the Providers.</li>
                                    <li>We reserve the right to apply certain fees to cover the administrative costs resulting from any modification or cancellation of booking from your end, on top of any fees charged by Providers in this regard.</li>
                                    <li>We reserve the right to cancel any Bookings due to non-payment of dues. We will notify you in such cases.  </li>
                                    <li>We may cancel any bookings made based on patent errors in the Platform (e.g., if the quoted price is a fraction of the customary price of the respective Service, or is otherwise unrealistic). You are kindly requested to inform us if you come across information which appears to be utterly wrong.  </li>
                                    <li>Overbooking. If a hotel is overbooked, then the hotel may offer you alternative accommodation. We will advise you immediately, upon receipt of such notice from the Provider, and you will be free to accept or decline and cancel the reservation with us within 24 hours from our notification. For clarify, we will bear no liability towards you and/or towards any person for whom a booking had been made for any such overbooking or for any failure to find alternative accommodation. Please note that if you choose to re-book a different hotel or facility at a higher price you will be required to pay the difference.</li>
                                    <li>No-shows. A no-show by the passenger without prior notice shall be considered a cancellation. No reimbursement shall be made in the event of a 'no-show' without prior consultation with and express authorization from us.</li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='User Conduct'>User Conduct</div>
                                <ol>
                                    <li>Lawful Use. Use the Platform for lawful purposes and in compliance with these Terms.</li>
                                    <li>Prohibited Activities. You agree not to:</li>
                                    <ol type='a'>
                                        <li>Disrupt or damage the Platform.</li>
                                        <li>Use automated tools to access the Platform without permission.</li>
                                        <li>Distribute unsolicited commercial content.</li>
                                        <li>Impersonate anyone.</li>
                                        <li>Violate any applicable laws or regulations.</li>
                                    </ol>
                                    <li>We shall not be liable to you or to the Providers for the behavior of any travelers included in a booking in the event of abnormal behavior, vandalism, or misconduct. In such cases, we and/or the Providers reserve the right to cancel the stays or reservations included in the bookings related to such travelers (regarding all or some of the travelers covered by such booking) with no right of the respective travelers to any compensation whatsoever.</li>
                                    <li>Any incidents that may and could be resolved during the provision of the Services should be addressed and resolved by the travelers and the Providers directly.</li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Intellectual Property'>Intellectual Property</div>
                                <ol>
                                    <li>Platform Ownership. The Platform and its content are owned by Volero and protected by intellectual property laws.</li>
                                    <li>Limited License. You have a limited, non-exclusive, non-transferable license to use the Platform for personal, non-commercial purposes.</li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Disclaimers and Liability'>Disclaimers and Liability</div>
                                <ol>
                                    <li>We are not responsible for the costs that may arise from the incorrect and/or unskilled use of the Platform or the misinterpretation of the information contained therein. It is your responsibility to check the voucher and air ticket for any errors. </li>
                                    <li>We are doing our best to maintain the Platform fully operative at all times but cannot guarantee that the operation of the Platform shall be error-free, shall not be affected by viruses or other hostile actions that can generate failures or other losses. The same applies to other information contained in the Platform and the Services provided by Providers, or through links which are accessible through the Platform. We are therefore not responsible for the unavailability of third parties' websites or for Providers' policies, privacy practices and/or content.</li>
                                    <li>We do not guarantee the accuracy or completeness of information on the Platform. Reliance is at your own risk. Also - the information posted on the Platform is dynamic and can be changed frequently. We may update the information in the Platform without notifying you.</li>
                                    <li>We are not responsible for technical issues that may arise with any Providers.</li>
                                    <li>We are not responsible for Providers' actions or omissions. We will exercise reasonable efforts to ensure that the respective Providers accept liability and indemnify from and against any claims arising in connection with the provision of the Services if you inform us of such claims in a timely manner.  However, we have no control over the provision of Travel Products by Providers. Without derogating from the above We are not, and shall not be responsible for, and will not compensate you for delays, cancellations, strikes or other events that are not under Our direct control.</li>
                                    <li>Liability Limits. Volero's liability for damages is limited as permitted by law. We are not liable for indirect, incidental, or consequential damages. Our maximum liability to you and/or to any traveler(s) covered by a booking for any reasonable and foreseeable loss, damage or liability which a passenger may suffer or incur as a result of our failure, breach of contract, or the deliberate or negligent acts or omissions of any of our employees, shall be limited to the reservation price booked.</li>
                                </ol>
                                <br /><br />

                                <div className='subtitle' id='Force Majeure'>Force Majeure</div>
                                <p>Without limitation from the above, we shall have no liability for any delay or failure to provide our services or for breach of contract when caused by a circumstance beyond our reasonable control. The following shall, without restriction, be considered to be circumstances beyond our reasonable control: war or threat of war, accidents causing delays on the service route, exceptional severe weather conditions, fire and/or damage at a station, compliance with requests from the police, customs or other government officials and security services, deaths and accidents, pandemics, vandalism and terrorism, unforeseen traffic delays, communication issues, cyber strikes, strike/industrial action, riot or local disturbance or unrest, problems caused by other final consumers, bankruptcy, insolvency or cessation of trade of any Providers used and other circumstances affecting passenger safety.</p>
                                <br /><br />

                                <div className='subtitle' id='Indemnification'>Indemnification</div>
                                <p>You agree to indemnify and hold Volero harmless from any claims, losses, or expenses arising from your use of the Platform or violation of these Terms.</p>
                                <br /><br />

                                <div className='subtitle' id='Governing Law and Dispute Resolution'>Governing Law and Dispute Resolution</div>
                                <p>Your use of the Platform and any disputes related to the Platform and/or to your use thereof are subject to the laws of Israel (without regard to its conflict of law rules).</p>
                                <p>The competent courts in the Tel-Aviv district, Israel, shall have exclusive jurisdiction over any dispute concerning this Platform and/or your use thereof. However, any dispute between you and any Provider may be subject to the terms and conditions applied by such Provider.   By using the Platform, you hereby agree to the above law and jurisdictions provisions and irrevocably waives any right that you may have to object to an action being brought in such courts, to claim that the action has been brought in an inconvenient forum, or to claim that such courts do not have jurisdiction.</p>
                                <br /><br />

                                <div className='subtitle' id='Modifications'>Modifications</div>
                                <p>Volero may modify these Terms at any time. Changes are effective upon posting. Continued use constitutes acceptance of the modified Terms.</p>
                                <br /><br />

                                <div className='subtitle' id='Contact Us'>Contact Us</div>
                                <p>For questions, contact us at info@volero.net.</p>
                                <br /><br />

                            </div>
                            <div className={`terms__list ${isFixed ? 'fixed' : ''}`}>
                                <div className='subtitle'>Table of Contents</div>
                                <div className='terms__link' onClick={() => scrollToSection('Introduction')}>Introduction</div>
                                <div className='terms__link' onClick={() => scrollToSection('Services')}>Services</div>
                                <div className='terms__link' onClick={() => scrollToSection('General information published on the Platform')}>General information published on the Platform</div>
                                <div className='terms__link' onClick={() => scrollToSection('User Account')}>User Account</div>
                                <div className='terms__link' onClick={() => scrollToSection('Bookings')}>Bookings</div>
                                <div className='terms__link' onClick={() => scrollToSection('Booking Confirmation')}>Booking Confirmation</div>
                                <div className='terms__link' onClick={() => scrollToSection('Prices')}>Prices</div>
                                <div className='terms__link' onClick={() => scrollToSection('Cancellations, Changes and Refunds')}>Cancellations, Changes and Refunds</div>
                                <div className='terms__link' onClick={() => scrollToSection('User Conduct')}>User Conduct</div>
                                <div className='terms__link' onClick={() => scrollToSection('Intellectual Property')}>Intellectual Property</div>
                                <div className='terms__link' onClick={() => scrollToSection('Disclaimers and Liability')}>Disclaimers and Liability</div>
                                <div className='terms__link' onClick={() => scrollToSection('Force Majeure')}>Force Majeure</div>
                                <div className='terms__link' onClick={() => scrollToSection('Indemnification')}>Indemnification</div>
                                <div className='terms__link' onClick={() => scrollToSection('Governing Law and Dispute Resolution')}>Governing Law and Dispute Resolution</div>
                                <div className='terms__link' onClick={() => scrollToSection('Modifications')}>Modifications</div>
                                <div className='terms__link' onClick={() => scrollToSection('Contact Us')}>Contact Us</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <ScrollToTopButton />
        </div>
    )
}
